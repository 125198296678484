<template>
    <div class="main" id="layout">
        <router-view>
                <!-- 这里是不被缓存的视图组件，比如 page3 -->
        </router-view>

        <div class="push"></div>
    </div>
</template>

<script>
    export default {
        name: "Layout",
        data() {
            return {
                active: 0,
                props:[],
                icon: {
                    active: 'https://img.yzcdn.cn/vant/user-active.png',
                    inactive: 'https://img.yzcdn.cn/vant/user-inactive.png'
                },
                orderReplace:true
            }
        },
        mounted() {
        },
        methods:{
        }

    }
</script>

<style >
    html {
        height: 100%;
        margin: 0;
        background-color: 	rgb(255,255,255) ;
    }
    body {
        width: 100%;
        height: 100%;
        display: flex; /*flex弹性布局*/
        justify-content: center;
        align-items: center;
        background-color: 	rgb(255,255,255) ;
    }
    .wrapper {
        min-height: 100%;
        /* Equal to height of footer */
        /* But also accounting for potential margin-bottom of last child */
        margin-bottom: -50px;
    }
    .footer,
    .push {
        height: 50px;
    }
    .title {
        margin: 0 auto;
        text-align: center;
        background-color: rgba(42, 130, 228, 1);
        height: 50px;
        color: rgb(238, 242, 247);
        display: flex;
        flex-direction: column; /*元素的排列方向为垂直*/
        justify-content: center; /*水平居中对齐*/
        align-items: center; /*垂直居中对齐*/
    }

    .van-nav-bar {
        background-color: rgba(42, 130, 228, 1) !important;
    }
    .van-nav-bar .van-icon{
        color: rgb(238, 242, 247)!important;
    }
    .van-nav-bar__title {
        color: rgb(238, 242, 247) !important;
    }
    .van-nav-bar__text{
        color: rgb(238, 242, 247) !important;
    }
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    .main{
        background-color: whitesmoke;
    }
</style>
