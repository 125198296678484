<template>
  <div id="app">
    <keep-alive>   
      <router-view v-if="$route.meta.keepAlive" v-wechat-title="$route.meta.title">
                <!-- 这里是会被缓存的视图组件，比如 page1,page2 -->   
      </router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" v-wechat-title="$route.meta.title">
        <!-- 这里是不被缓存的视图组件，比如 page3 -->
    </router-view>
    <Overlay></Overlay>
    <van-number-keyboard safe-area-inset-bottom />
  </div>
</template>

<script>

  import Overlay from "./components/overlay/Overlay";

  export default {
    name: 'App.Vue',
    components: {Overlay},
    created() {
    },
    mounted() {
      window.addEventListener('hashchange', () => {
        let currentPath = window.location.hash.slice(1); // 获取输入的路由
        if (this.$router.path !== currentPath) {
          this.$router.push(currentPath); // 动态跳转
        }
      }, false);
    }
  }
</script>

<style>
  #app {
    width: 100%;
    height: 100%;
  }
  html{
    font-size: 62.5%;
  }
</style>

