import axios from 'axios'
import store from "../store"
import {Notify} from 'vant';

// 设置了路由拦截  这里清掉token 就会跳回登录页


// 请求拦截器
axios.interceptors.request.use(
    config => {
        let token = localStorage.getItem('token')
        if (token) {
            config.headers['token'] = token

        } else {
            config.headers['token'] = ''
        }
        return config
    },
    err => {
        return Promise.reject(err)
    })

// 响应拦截器
axios.interceptors.response.use(res => {
    let data = res.data
    console.log(res)
    let that = this
    store.commit('hide')
    if (data.Code === 0) {
        return res.data
    } else if (data.Code === 1) {
        //1 登录过期
    } else {
        console.log('错误~~')
        console.log(res)
        Notify(data.Message);
        return null;
    }
}, error => {
    console.log(error)
})

export default axios