import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isShowLoading: false,
    },
    mutations: {
        show(state) {
            state.isShowLoading = true
        },
        hide(state) {
            state.isShowLoading = false
        },
    },
    actions: {
        showAsync({commit}) {
            commit('show')
            setTimeout(() => {
                commit('hide')
            }, 30000)
        }
    }
})

