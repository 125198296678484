<template>
    <div>
        <van-overlay :show="isShowLoading" @click="isShowLoading = false">
            <div class="wrapper" @click.stop>
                <van-loading type="spinner" size="30"/>
            </div>
        </van-overlay>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: "overlay",
        computed: {
            ...mapState(['isShowLoading'])
        }
    }
</script>

<style scoped>

</style>
