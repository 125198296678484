<template>
    <div>
        <div v-if="preOrder!=null">
            <vant-sticky>
                <van-nav-bar :title="preOrder.CompanyName"></van-nav-bar>
            </vant-sticky>
            <van-form ref="preOrder" @submit="onSubmit">
                <van-cell-group title="订单信息">
                    <van-cell title="订单编号" title-class="cell_title" :value="preOrder.OrderNo" />
                    <van-cell title="订单内容" title-class="cell_title" :value="preOrder.OrderContent" />
                    <van-cell title="订单价格" title-class="cell_title" :value="preOrder.Price+'元'" />
                </van-cell-group>
                <van-cell-group title="客户信息">
                    <van-field
                            v-model="preOrder.fieldValue"
                            is-link
                            readonly
                            label="游戏区服"
                            placeholder="请选择游戏区服"
                            @click="show = true"
                            required
                            :rules="[{ required: true, message: '请选择游戏区服' }]"
                    />
                    <van-popup v-model="show" round position="bottom">
                        <van-sticky>
                            <van-picker title="请选择区服" show-toolbar value-key="text" :columns="games"
                                      @cancel="onCancel"  @confirm="onConfirm" @change="onChange" :loading="gameLoading"/>
                        </van-sticky>
                    </van-popup>
                    <van-field
                            v-model="preOrder.bizTypeName"
                            is-link
                            readonly
                            name="bizType"
                            label="类型"
                            placeholder="类型"
                            @click="showBizType = true"
                            required
                            :rules="[{ required: true, message: '请选择类型' }]"
                    />
                    <van-popup v-model:show="showBizType" position="bottom">
                        <van-picker
                                title="请选择类型" show-toolbar value-key="text"
                                :columns="bizTypes"
                                 @confirm="onBizTypeConfirm"
                                @cancel="showBizType = false"
                        />
                    </van-popup>

                    <van-field
                            v-model="preOrder.GameAccount"
                            name="游戏账号"
                            label="游戏账号"
                            placeholder="游戏账号"
                            required
                            :rules="[{ required: true, message: '请填写密码' }]"
                    />
                    <van-field
                            v-model="preOrder.GamePassword"
                            name="游戏账户密码"
                            label="游戏账户密码"
                            placeholder="游戏账户密码"
                            required
                            :rules="[{ required: true, message: '请填写密码' }]"
                    />
                    <van-field
                            v-model="preOrder.RoleName"
                            name="	游戏角色名称"
                            label="	游戏角色名称"
                            placeholder="游戏角色名称"
                            required
                            :rules="[{ required: true, message: '请填写密码' }]"
                    />
                    <van-field
                            v-model="preOrder.CellPhone"
                            name="联系电话"
                            label="联系电话"
                            placeholder="联系电话"
                            required
                            :rules="[{ required: true,validator, message: '请输入正确的手机格式' }]"
                    />

                </van-cell-group>
                <template  v-for="item in orderFieldList">
                    <van-field
                            v-model="item.Value"
                            :name="item.Name"
                            :label="item.Name"
                            :placeholder="item.Name"
                            required
                            :rules="[{ required: true, message: '请输入'+item.Name }]"
                    />
                </template>
                    <van-notice-bar
                            wrapable
                            :scrollable="false"
                            text="信息已提交，如有错误需纠正，请联系客服，将此单状态重置为”待填写“状态，即可修改信息。"
                            v-if="preOrder.Status!=0 && preOrder.Status!=1"
                    />
                    <div v-if="preOrder.Status==1" style="margin: 16px;">
                        <van-button round block type="info" native-type="submit"
                                    :loading="submitLoading" loading-text="录入中..."
                                    >提交
                        </van-button>
                    </div>

            </van-form>
        </div>
        <van-empty image="search" description="没有找到数据,请联系客服" v-if="preOrder==null"/>
    </div>
</template>

<script>
    import Notify from "vant/lib/notify";

    export default {
        name: "OrderInput",
        data() {
            return {
                preOrder: {
                    Id: 0,
                    Guid: "",
                    CompanyId: 0,
                    CompanyName: "",
                    OrderSource: 5,
                    OrderSourceName: "",
                    OrderNo: "",
                    OrderContent: "",
                    Price: 0,
                    Hours: 0,
                    GameId: 0,
                    GameName: '',
                    GameZoneId: 0,
                    GameZoneName: "",
                    GameServerId: 0,
                    GameServerName: "",
                    BizTypeId: 0,
                    BizTypeName: "",
                    GameAccount: '',
                    GamePassword: "",
                    RoleName: '',
                    CellPhone: '',
                    QQ: '',
                    WW: '',
                    Remark: '',
                    Status: 0,
                    OrderId: 0,
                    Deleted: false,
                    CreateTime: '',
                    OrderFieldExtends:[],
                    fieldValue: '',
                    bizTypeName:''
                },
                show: false,
                showBizType:false,
                cascaderValue: '',
                zoneList: [],
                submitLoading: false,
                games: [
                    {
                        values: '',
                        className: 'column1'
                    },
                    {
                        values: '',
                        className: 'column2',
                        defaultIndex: 0
                    },
                    {
                        values: '',
                        className: 'column3',
                        defaultIndex: 0
                    }
                ],
                showPicker: false,
                price: 0,
                gameLoading:false,
                orderFieldList:{},
                guid:'',
                bizTypes:[]
            };
        },
        mounted() {
            this.guid = this.$route.query.guid;
            this.loadOrder()
            this.getGames()

        },
        methods: {
            loadOrder() {
                let that = this

                this.$store.dispatch('showAsync')
                this.$http.get('/api/Order/Query', {params: {guid: this.guid}}).then(res => {
                    that.preOrder = res.Data
                    if(that.preOrder.OrderSource==38){
                        that.$router.push({path:'/tbOrder',query:{guid:that.guid}});
                    }
                    if(that.preOrder.GameName!=''){
                        that.preOrder.fieldValue = that.preOrder.GameName + '/' + that.preOrder.GameZoneName  + '/' + that.preOrder.GameServerName
                    }
                    that.getBizType(this.preOrder.GameId).then(biz=>{
                        let name=biz.find(item=>{
                            if(item.code==that.preOrder.BizTypeId){
                                return item
                            }
                        })
                        if(name!=null){
                            that.preOrder.bizTypeName=name.text
                        }else{
                            that.preOrder.bizTypeName=''
                        }
                    })
                    document.title=that.preOrder.CompanyName
                    that.getOrderFieldExtendInfo(that.preOrder.GameId)

                })
            },
            onChange(picker, values, index) {
                let that = this
                if (index == 0) {
                    that.getZones(values[0].code).then((data)=>{
                        picker.setColumnValues(1,data)
                        if(data.length>0){
                            that.getServers(data[0].code).then((data)=>{

                                picker.setColumnValues(2,data)
                            })
                        }
                    })
                }else if(index==1){
                    that.getServers(values[1].code).then((data)=>{
                        picker.setColumnValues(2,data)
                    })
                }
            },
            onSubmit() {
                let that = this
                this.submitLoading = true
                this.$store.dispatch('showAsync')
                this.$refs.preOrder.validate().then(()=>{ // 验证通过

                            let orderFields=  this.orderFieldList.map((item,index)=>{
                                return {Key:item.InnerID,Value:item.Value}
                            })
                            that.preOrder.OrderFieldExtends=orderFields
                        that.$http.post('/api/Order/Update', that.preOrder).then(res => {
                                that.submitLoading = false
                                if (res != null) {
                                    Notify({type: 'primary', message: '提交成功'});
                                    that.loadOrder()
                                }
                            })

                }).catch(()=>{ //验证失败
                    that.submitLoading = false
                    this.$notify('验证失败');
                })

            },
            validator(val) {
                if (val == '') {
                    return true
                } else {
                    return /^1[0123456789]\d{9}$/.test(val);
                }
            },
            getGames() {
                let that = this
                this.$http.get('/api/Game/GameList', {params: {guid: this.guid}}).then(res => {
                    let data = res.Data

                    that.games[0].values = Object.values(res.Data).map(function (e) {
                        return {text: e.Name, code: e.ID}
                    })

                    that.getZones(that.games[0].values[0].code).then(zoneData=>{
                        that.games[1].values = zoneData
                        that.getServers(zoneData[0].code).then(serverData=>{
                            that.games[2].values=serverData
                        })
                    })
                });
            },
            getZones(gameId) {
                let that = this
                that.gameLoading=true
                let promise=new Promise(function (resolve, reject) {
                    that.$http.get('/api/Game/ZoneList', {
                        params: {
                            gameId: gameId
                        }
                    }).then(res => {
                        that.gameLoading=false
                        if (res == null) {
                            return
                        }
                        let data = Object.values(res.Data).map(function (e) {
                            return {text: e.Name, code: e.ID}
                        })

                        resolve(data)
                    })
                })
                return promise
            },
            getServers(zoneId) {
                let that = this
                that.gameLoading=true
                let promise=new Promise(function (resolve, reject){
                    that.$http.get('/api/Game/ServerList', {params: {zoneId: zoneId}}).then(res => {
                        that.gameLoading=false
                        let data = Object.values(res.Data).map(function (e) {
                            return {text: e.Name, code: e.ID}
                        })

                        resolve(data)
                    })
                })
                return promise
            },
            onConfirm(value) {
                this.preOrder.fieldValue = value.map((option) => option.text).join('/');
                this.preOrder.GameId=value[0].code
                this.preOrder.GameZoneId=value[1].code
                this.preOrder.GameServerId=value[2].code
                this.getBizType(this.preOrder.GameId)
                this.getOrderFieldExtendInfo(this.preOrder.GameId)
                this.show = false;
            },
            onCancel(){
                this.show = false;
            },
            getOrderFieldExtendInfo(gameId){
                let that=this
                let data={
                    guid:this.guid,
                    gameId:gameId
                }
                this.$http.get('/api/Order/Query/OrderFieldExtend',{params:data}).then(res=>{
                    if(res!=null){
                        that.orderFieldList=res.Data
                    }else{
                        that.orderFieldList=[]
                    }
                })
            },
            getCompanyName(){
                return {title:this.preOrder.CompanyName==undefined?'':this.preOrder.CompanyName}
            },
            onBizTypeConfirm(value){
                console.log(value)
                this.preOrder.BizTypeId=value.code
                this.preOrder.bizTypeName=value.text
                this.showBizType=false
            },
            getBizType(gameId){
                let that=this
                let data={
                    gameId:gameId
                }
               return new Promise(resolve => {
                    this.$http.get('/api/Game/BizTypeList',{params:data}).then(res=>{
                        if(res!=null){
                            that.bizTypes = Object.values(res.Data).map(function (e) {
                                return {text: e.Name, code: e.ID}
                            })
                            resolve(that.bizTypes)
                        }else{
                            that.bizTypes=[]
                        }
                    })
                })

            }
        }
    }
</script>
<style scoped>
    .cell_title{
        color: #646566;
        text-align:left;
        width: 6.2em;
    }
    /deep/ .van-cell-group--inset{
        margin: 0;
    }
</style>
