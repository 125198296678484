import Vue from 'vue'
import Router from 'vue-router'
import Layout from './components/layout/Layout'
import OrderInput from "@/Views/OrderInput/OrderInput";
import TBOrderInput from "./Views/OrderInput/TBOrderInput";
import {route} from "vant/lib/utils/router";

Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

export default new Router({
    mode:'history',
    routes: [
        {
            path: '/',
            name: 'layout',
            component: Layout,
            meta: {
                title: '服务订购'
            },
            children: [
                {
                    path: '',
                    name: 'orderInput',
                    component: OrderInput,
                    hidden:true,
                    meta:{
                        title:'订单管理'
                    }
                },
                {
                    path: '/tbOrder',
                    name: 'tbOrder',
                    component: TBOrderInput,
                    hidden:true,
                    meta:{
                        title:'淘宝订单管理'
                    }
                },
            ],
        },
    ],
})
