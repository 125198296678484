import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Layout from './components/layout/Layout'
import 'vant/lib/index.css';
import http from './config/http';
import {Notify} from 'vant';
import store from "./store";
import {ImagePreview} from 'vant';
import Common from "./api/common";

// 全局注册
Vue.use(ImagePreview);
Vue.use(Notify)


let common=new Common();

Vue.config.productionTip = false
Vue.prototype.$http = http
Vue.prototype.$store = store
Vue.prototype.$common=common
let app = new Vue({
  store,
  router,
  component: {Layout},
  template: '<Layout/>',
  render: h => h(App)
}).$mount('#app')
let url = window.location.href.split("#")[0];
if (url.indexOf('from') != -1) {
  url = window.location.href.split("?")[0];
}
